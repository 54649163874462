body {

  
  margin: 0;
  font-family: 'Roboto Condensed', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  -webkit-user-drag: none; /* Safari */
  user-drag: none;
}

.p-sidebar .p-sidebar-content {
  padding: 0rem !important;
}
.basefont {

  
  margin: 0;
  font-family: 'Roboto Condensed', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  -webkit-user-drag: none; /* Safari */
  user-drag: none;
}
.p-sidebar .p-sidebar-header {
  padding: 0px !important;
}
.p-menu .p-submenu-header {
  padding: 0px !important;
}
.p-tabview .p-tabview-panels {
  padding: 0px !important; 
}
.p-tabview .p-tabview-panels {
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0rem #bfdbfe !important;
  border-color: unset !important;
}

.p-inputtext:enabled:hover {
  border-color: unset !important;
}


.p-dropdown:not(.p-disabled):hover {
  border-color:unset !important;
}

.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: unset !important;
  border-color:unset !important;
}



.p-dialog {
  max-height: unset !important;
  border-radius: 0px !important;
}

.p-link:focus-visible {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: unset !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: #374151;
  border-color: transparent;
  background: unset !important;
}

.p-button:focus {
  box-shadow: unset !important;
}

.p-dropdown-items-wrapper{
  max-height: 90% !important;

}




html, body {
  touch-action: manipulation; /* Prevent gestures like pinch-zoom */
  -ms-touch-action: manipulation; /* For older Microsoft Edge browsers */
  -webkit-user-select: none; /* Disable text selection */
  -ms-user-select: none; /* Disable text selection for Internet Explorer */
  user-select: none; /* Disable text selection in modern browsers */
  overflow-x: hidden; /* Optional: Prevent horizontal scrollbars */
}